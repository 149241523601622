import ConceptHouses from "./views/concept";
import FurnishedHouses from "./views/furnished";
import Home from "./views/home";
import Offer from "./views/offer";
import RentalHouses from "./views/rental";
import SaleHouses from "./views/sale";

const routes = [
  {
    name: "Home",
    path: "/",
    component: <Home />,
  },
  {
    name: "RentalHouses",
    path: "/maisons-a-louer",
    component: <RentalHouses />,
  },
  {
    name: "SaleHouses",
    path: "/maisons-a-vendre",
    component: <SaleHouses />,
  },
  {
    name: "FurnishedHouses",
    path: "/appartements-meubles",
    component: <FurnishedHouses />,
  },
  {
    name: "Offer",
    path: "/vendre-ma-maison",
    component: <Offer />,
  },
  {
    name: "Concept",
    path: "/plan-de-maison",
    component: <ConceptHouses />,
  },
];

export default routes;
