import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

const TepBusiness = () => {
  return (
    <Grid container sx={{ p: 8 }} direction={"row-reverse"} spacing={2}>
      <Grid
        item
        container
        justifyContent={"center"}
        alignItems={"center"}
        direction={"row"}
        xs={12}
        md={6}
      >
        <Box>
          <Typography
            fontWeight={"500"}
            color={"primary"}
            variant="h3"
            component={"h2"}
            textAlign={"center"}
          >
            Vous vendez votre maison?
          </Typography>

          <Typography textAlign={"center"}>
            Connectez à l'application mobile TEPimmo, soummettez votre offre.
          </Typography>
          <Typography textAlign={"center"} sx={{ my: 1 }}>
            <Button component="a" href="/vendre-ma-maison" variant="outlined">
              Voir comment ça marche{" "}
            </Button>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography textAlign={"center"}>
          <img src="/images/offer0.png" width={200} alt="tepBusiness" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TepBusiness;
