import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import React, { Fragment, useState } from "react";
import Favorite from "@mui/icons-material/Favorite";
import HouseModal from "./HouseModal";

const House = ({ data }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <Grid item xs={12} md={3}>
        <Box
          sx={{
            "&:hover": {
              borderWidth: 1,
              borderColor: theme.palette.primary.main,
            },
          }}
        >
          <Box sx={{ height: 300, overflow: "hidden" }}>
            <img src={data.images[0]} width={"100%"} />
          </Box>
          <Box sx={{ p: 3 }}>
            <Typography color={"primary"} variant="h6">
              {data.type}{" "}
            </Typography>
            <Typography>{data.description.slice(0, 200) + "..."} </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <Typography
                textAlign={"center"}
                sx={{
                  mt: 2,
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  p: 0.5,
                  width: "40%",
                  borderRadius: 1,
                  fontSize: 15,
                }}
              >
                {data.price} USD
              </Typography>
              <Box>
                <HouseModal open={open} setOpen={setOpen} house={data} />
                <IconButton sx={{ alignSelf: "center", mt: 1.5 }}>
                  <Favorite />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default House;
