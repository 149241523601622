import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#22b3b1",
    },
    secondary: {
      main: "#f8e623",
    },
    background: "#072727",
  },
  typography: {
    button: {
      textTransform: "normal",
    },
  },
});

export default theme;
