import { Box, Grid, Typography } from "@mui/material";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React from "react";
import { useState } from "react";
import SearchBar from "../includes/SearchBar";
import { useEffect } from "react";
import { db } from "../../firebase";
import House from "../product/House";

const TepMarket = () => {
  const [houses, setHouses] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [priceFilter, setPriceFilter] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const colRef = collection(db, "products");
    const q = query(
      colRef,
      where("status", "==", "validated"),
      orderBy("createdAt", "desc"),
      limit(12)
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setHouses(content);
        setData(content);
        setLoading(false);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, [houses]);

  const searchFilter = (text) => {
    const newData =
      houses &&
      houses.filter((item) => {
        const itemData = item.description
          ? item.description.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
  };
  return (
    <Grid container sx={{ p: 8, backgroundColor: "#eee" }} spacing={2}>
      <Grid item xs={12}>
        <Box>
          <Typography
            textAlign={"center"}
            color={"primary"}
            variant="h3"
            component={"h2"}
          >
            Une recherche de maison plus facile et sans prise de tête!
          </Typography>
          <Typography textAlign={"center"}>
            Rechercher plus facile une maison sur TEPimmo, tapez la simplement
            description de la maison recherchée.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SearchBar
          searchFilter={searchFilter}
          placeholder={"Ex: 3 chambres, salon, cuisine, ..."}
        />
      </Grid>
      {data && data.map((house) => <House data={house} />)}
    </Grid>
  );
};

export default TepMarket;
