import React from "react";
import HomeBanner from "../../components/home/Banner";
import TepBusiness from "../../components/home/TepBusiness";
import TepMarket from "../../components/home/TepMarket";

const Home = () => {
  return (
    <main>
      <HomeBanner />
      <TepBusiness />
      <TepMarket />
    </main>
  );
};

export default Home;
