export const menuList = [
  {
    name: "Maisons à louer",
    href: "/maisons-a-louer",
  },
  {
    name: "Maisons à vendre",
    href: "/maisons-a-vendre",
  },
  {
    name: "Appartements meublés",
    href: "/appartements-meubles",
  },
  // {
  //   name: "Décoration intérieure & extérieure",
  //   href: "/decoration",
  // },
  {
    name: "Plans de maison",
    href: "/plan-de-maison",
  },
  {
    name: "Vendre ma maison",
    href: "/vendre-ma-maison",
  },
];
