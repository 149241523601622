import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

const Offer = () => {
  const theme = useTheme();
  return (
    <main>
      <Grid
        sx={{ p: 8, backgroundColor: theme.palette.background }}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="h3" textAlign={"center"} color={"secondary"}>
            Comment vendre sa maison ?
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ p: 8 }} direction={"row-reverse"} spacing={2}>
        <Grid
          item
          container
          justifyContent={"center"}
          alignItems={"center"}
          direction={"row"}
          xs={12}
          md={6}
        >
          <Box>
            <Typography color={"primary"} variant="h3" component={"h2"}>
              Connectez-vous à TEPimmo
            </Typography>
            <Typography>
              Télécharger TEPimmo depuis AppStore ou Google Play, connectez-vous
              à votre profil pour pouvoir soummettre votre offre.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/images/connectApp.png" width={300} alt="tepBusiness" />
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ p: 8 }} direction={"row"} spacing={2}>
        <Grid
          item
          container
          justifyContent={"center"}
          alignItems={"center"}
          direction={"row"}
          xs={12}
          md={6}
        >
          <Box>
            <Typography color={"primary"} variant="h3" component={"h2"}>
              Allez sur vendre ma maison
            </Typography>
            <Typography>
              Une fois connectée, cliquez sur la case "Vendre, mettre en
              location ma maison" pour pouvoir soumttre votre offre.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/images/offer0.png" width={300} alt="tepBusiness" />
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ p: 8 }} direction={"row-reverse"} spacing={2}>
        <Grid
          item
          container
          justifyContent={"center"}
          alignItems={"center"}
          direction={"row"}
          xs={12}
          md={6}
        >
          <Box>
            <Typography color={"primary"} variant="h3" component={"h2"}>
              Remplissez le formulaire et soumettez votre offre
            </Typography>
            <Typography>
              Remplissez le formulaire en important vos images ou videos de
              votre maison, décrivez le motif de votre offre, vendre ou mettre
              en location puis soumettez votre offre.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/images/offer1.png" width={300} alt="tepBusiness" />
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ p: 8 }} direction={"row"} spacing={2}>
        <Grid
          item
          container
          justifyContent={"center"}
          alignItems={"center"}
          direction={"row"}
          xs={12}
          md={6}
        >
          <Box>
            <Typography color={"primary"} variant="h3" component={"h2"}>
              Recevez une notification de votre offre disponible sur TEPimmo
            </Typography>
            <Typography>
              Une fois soumis rendez-vous sur l'onglet 'Mes offres' pour pouvoir
              suivre l'examen de votre offre, une fois votre offre validée, nous
              vous notifions de sa disponibilté dans la marketPlace TEPimmo.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"center"}>
            <img src="/images/offer2.png" width={300} alt="tepBusiness" />
          </Typography>
        </Grid>
      </Grid>
    </main>
  );
};

export default Offer;
