import { Route, Routes } from "react-router-dom";
import routes from "./routes";
import NotFound from "./views/notFound";
import Layout from "./components/Layout";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="*" element={<NotFound />} />
        {routes.map((route) => (
          <Route exact path={route.path} element={route.component} />
        ))}
      </Routes>
    </Layout>
  );
}

export default App;
