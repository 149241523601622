import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import SearchBar from "../../components/includes/SearchBar";
import House from "../../components/product/House";
import { useState } from "react";
import { useEffect } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";

const SaleHouses = () => {
  const theme = useTheme();
  const [houses, setHouses] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const colRef = collection(db, "products");
    const q = query(
      colRef,
      where("status", "==", "validated"),
      where("category", "==", "sale"),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setHouses(content);
        setData(content);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, [houses]);

  const searchFilter = (text) => {
    const newData =
      houses &&
      houses.filter((item) => {
        const itemData = item.description
          ? item.description.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
  };
  return (
    <main>
      <Grid
        sx={{ p: 8, backgroundColor: theme.palette.background }}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="h3" color={"secondary"} textAlign={"center"}>
            Maisons à vendre
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ p: 8, backgroundColor: "#eee" }} spacing={2}>
        <Grid item xs={12}>
          <SearchBar
            searchFilter={searchFilter}
            placeholder={"Ex: 3 chambres, salon, cuisine, ..."}
          />
        </Grid>
        {data && data.map((house) => <House data={house} />)}
      </Grid>
    </main>
  );
};

export default SaleHouses;
