import { Box, Button, Grid, Typography, alpha, useTheme } from "@mui/material";
import React from "react";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";

const HomeBanner = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        px: { md: 10, xs: 6 },
        pt: { xs: 6, md: false },
        overflow: "hidden",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.primary.main, 0.3)},
         ${alpha("#000", 0.4)}
      ), url('/images/ban1bck.jpg')`,
      }}
      spacing={2}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        item
        xs={12}
        spacing={2}
        md={6}
      >
        <Box
          sx={{
            backgroundColor: alpha(theme.palette.background, 0.6),
            p: 3,
            borderRadius: 8,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Typography
                  textAlign={{ md: "justify", xs: "center" }}
                  color={"white"}
                  variant={{ md: "h3", xs: "h4" }}
                  component={"h1"}
                >
                  Nous vous aidons à{" "}
                  <span style={{ color: theme.palette.primary.main }}>
                    trouver
                  </span>{" "}
                  ou à{" "}
                  <span style={{ color: theme.palette.primary.main }}>
                    vendre
                  </span>{" "}
                  votre{" "}
                  <span style={{ color: theme.palette.secondary.main }}>
                    maison
                  </span>
                  .
                </Typography>
                <Typography
                  textAlign={{ md: "justify", xs: "center" }}
                  color={"white"}
                >
                  Des maisons à louer, des maisons à vendre, des appartemnents
                  meublés, des plans de maison, tous sont disponibles,
                  téléchargez l'appli.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                sx={{ color: "white" }}
                variant="outlined"
                startIcon={<AppleIcon />}
                href="https://bit.ly/45qf53R"
              >
                Télécharger sur AppStore
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                sx={{ color: "white" }}
                variant="outlined"
                startIcon={<GoogleIcon />}
                href="https://bit.ly/3orAlFE"
              >
                Télécharger sur Google Play
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography sx={{ mb: -1 }} textAlign={"center"}>
          <img src="/images/ban1.png" width={400} alt="banner1" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HomeBanner;
